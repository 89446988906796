<template>
  <b-container fluid class="p-0">
    <div class="box-kyc">
      <div class="box-title">
        <div class="text">Personal Detail</div>
        <div class="icon" :class="{ active: infoshow }" @click="infoshow = !infoshow">
          <img src="~@/assets/images/icons/chevron-down.png" alt="" />
        </div>
      </div>
      <div class="content-box" v-if="infoshow">
        <b-form @submit.prevent="onUpdataInfo">
          <b-row>
            <b-col md="6" lg="12" xl="6" cols="12">
              <div class="form-group">
                <label for="firtname">First Name</label>
                <b-form-input
                  type="text"
                  required
                  id="firtname"
                  v-model.trim="userinfo.surname"
                  placeholder="Enter Your First Name"
                ></b-form-input>
              </div>
            </b-col>
            <b-col md="6" lg="12" xl="6" cols="12">
              <div class="form-group">
                <label for="lastname">Last Name</label>
                <b-form-input
                  type="text"
                  required
                  id="lastname"
                  v-model.trim="userinfo.name"
                  placeholder="Enter Your Last Name"
                ></b-form-input>
              </div>
            </b-col>
            <b-col md="6" lg="12" xl="6" cols="12">
              <div class="form-group">
                <label for="birthday">Birthday</label>
                <div class="birthday-input">
                  <div class="day">
                    <b-form-input
                      v-model.trim="birthday.date"
                      required
                      min="1"
                      max="31"
                      type="number"
                      placeholder="DD"
                    ></b-form-input>
                    <label for="birthday">Day (DD)</label>
                  </div>

                  <div class="month">
                    <b-form-input
                      v-model.trim="birthday.month"
                      required
                      min="1"
                      max="12"
                      type="number"
                      placeholder="MM"
                    ></b-form-input>
                    <label for="birthday">Month (MM)</label>
                  </div>

                  <div class="year">
                    <b-form-input
                      v-model.trim="birthday.year"
                      required
                      min="1900"
                      :max="new Date().getFullYear()"
                      type="number"
                      placeholder="YYYY"
                    ></b-form-input>
                    <label for="birthday">Year (YYYY)</label>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col md="6" lg="12" xl="6" cols="12">
              <div class="form-group">
                <label for="userAddress">Address</label>
                <b-form-input
                  type="text"
                  required
                  id="userAddress"
                  v-model.trim="userinfo.address"
                  placeholder="Enter Your Address"
                ></b-form-input>
              </div>
            </b-col>
            <b-col md="6" lg="12" xl="6" cols="12">
              <div class="form-group">
                <label for="phone">Phone</label>
                <VuePhoneNumberInput
                  v-model="phoneshow"
                  :dark="false"
                  id="phone"
                  required
                  size="lg"
                  color="#fff"
                  @update="OnValidationPhone"
                  :no-example="false"
                  dark-color="rgba(0, 0,0)"
                  :default-country-code="countryPhone"
                  error-color="#ff0000"
                />
              </div>
            </b-col>
            <b-col md="6" lg="12" xl="6" cols="12">
              <div class="form-group">
                <label for="ZipCode">ZipCode</label>
                <b-form-input
                  type="text"
                  required
                  id="ZipCode"
                  v-model.trim="userinfo.zipCode"
                  placeholder="Enter Your Regtion Code"
                  maxlength="8"
                  @change="CheckCode"
                ></b-form-input>
                <div class="text-error" v-if="userinfo.zipCode && !zipCode.zipcode && false">
                  Zip code does not exist in {{ this.userinfo.country }}
                </div>
              </div>
            </b-col>
            <b-col md="6" lg="12" xl="6" cols="12">
              <div class="form-group">
                <label for="placeBirthday">Place of birth</label>
                <b-form-input
                  type="text"
                  required
                  id="placeBirthday"
                  v-model.trim="userinfo.placeBirthday"
                  placeholder="Enter Your Place of birth"
                ></b-form-input>
              </div>
            </b-col>
            <b-col md="6" lg="12" xl="6" cols="12">
              <div class="form-group">
                <label for="Country">Country</label>
                <country-select
                  v-model="userinfo.country"
                  :countryName="true"
                  :country="userinfo.country"
                  topCountry="US"
                  id="Country"
                  required
                  disabled
                />
              </div>
            </b-col>
            <b-col cols="12" class="d-flex justify-content-center align-items-center mt-4">
              <b-button class="mx-2" variant="secondary" type="button" @click="onResetForm('info')">
                Reset
              </b-button>
              <b-button
                class="mx-2 btn-egg"
                variant="success"
                type="submit"
                :disabled="isValidZipCode"
              >
                {{ this.UserInfo.name ? 'Update' : 'Confirm' }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </div>
    <div class="box-kyc">
      <div class="box-title">
        <div class="text">
          Verification (KYC)
          <span class="text-warning">
            {{ UserKYC.status == 0 ? ' (waiting for approval)' : '' }}
          </span>
        </div>
        <div class="icon" :class="{ active: kycshow }" @click="kycshow = !kycshow">
          <img src="~@/assets/images/icons/chevron-down.png" alt="" />
        </div>
      </div>
      <div class="content-box" v-if="kycshow">
        <div class="mb-4 box" id="uploadImage">
          <div class="form-group">
            <label for="">ID/Passport Number</label>
            <input
              v-if="UserKYC.status !== -1"
              type="text"
              v-model="UserKYC.passport_id"
              class="form-control"
              readonly
              style="background: transparent"
            />
            <input v-else type="text" v-model="passport" class="form-control" />
          </div>
          <div class="box-change-img" v-if="UserKYC.status == -1">
            <div class="nav-kyc">
              <a class="item" @click="type = 1" :class="{ active: type == 1 }"> Use ID </a>
              <a class="item" @click="type = 2" :class="{ active: type == 2 }"> Use Passport </a>
            </div>
          </div>
          <template v-if="type == 2">
            <div class="form-group">
              <div class="imgInfo">
                <p class="text-left">
                  <span class="fileSize">Passport Image</span> <br />
                  <span class="fileSize text-success">
                    * Make sure the image is full information (your information, the information of
                    the origin passport) and clear.
                  </span>
                  <br />
                  <span class="fileSize text-success"> * Make sure the format is jpg, jpeg. </span>
                  <br />
                  <span class="fileSize text-success">
                    * Please use image up to maximum 10MB size
                  </span>
                </p>
              </div>
              <div class="avatarEmpty text-center" v-if="!passport_image && UserKYC.status == -1">
                <input
                  @change="onFileChange($event, 0)"
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  class="fileUpload"
                />
                <svg
                  data-v-de2bfc3e=""
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 89.156 95.1"
                >
                  <g
                    data-v-de2bfc3e=""
                    id="upload-and-send"
                    transform="translate(0 0)"
                    opacity="0.3"
                  >
                    <path
                      data-v-de2bfc3e=""
                      id="Path_34910"
                      data-name="Path 34910"
                      d="M25.038,1.016a2.972,2.972,0,0,1,4.476,0l20.8,23.775a2.972,2.972,0,0,1-2.241,4.927H36.188V68.353a2.972,2.972,0,0,1-2.972,2.972H21.329a2.972,2.972,0,0,1-2.972-2.972V29.719H6.469a2.972,2.972,0,0,1-2.235-4.927Z"
                      transform="translate(17.306 0)"
                      fill="#fefefe"
                    ></path>
                    <path
                      data-v-de2bfc3e=""
                      id="Path_34911"
                      data-name="Path 34911"
                      d="M77.269,11V28.831H11.888V11H0V34.775a5.944,5.944,0,0,0,5.944,5.944H83.213a5.944,5.944,0,0,0,5.944-5.944V11Z"
                      transform="translate(0 54.381)"
                      fill="#fefefe"
                    ></path>
                  </g>
                </svg>
                <p>Drag and drop a file here or click</p>
              </div>
              <div v-else class="avatarEmpty show-img">
                <img
                  v-lazy="UserKYC.status !== -1 ? UserKYC.passport_image : passport_image"
                  width="225"
                />
                <button
                  v-if="UserKYC.status == -1"
                  class="button removi-img block bg-theme-1 text-white"
                  @click="removeImage(0)"
                >
                  Remove
                </button>
              </div>
            </div>
          </template>
          <template v-if="type == 1">
            <div class="form-group">
              <div class="imgInfo">
                <p class="text-left">
                  <span class="fileSize">Front Of ID </span> <br />
                  <span class="fileSize text-success">
                    * Make sure the image is full and clear and the format is jpg, jpeg.
                  </span>
                  <br />
                  <span class="fileSize text-success">
                    * Please use image up to maximum 10MB size
                  </span>
                </p>
              </div>
              <div class="avatarEmpty text-center" v-if="!passport_image && UserKYC.status == -1">
                <input
                  @change="onFileChange($event, 0)"
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  class="fileUpload"
                />
                <svg
                  data-v-de2bfc3e=""
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 89.156 95.1"
                >
                  <g
                    data-v-de2bfc3e=""
                    id="upload-and-send"
                    transform="translate(0 0)"
                    opacity="0.3"
                  >
                    <path
                      data-v-de2bfc3e=""
                      id="Path_34910"
                      data-name="Path 34910"
                      d="M25.038,1.016a2.972,2.972,0,0,1,4.476,0l20.8,23.775a2.972,2.972,0,0,1-2.241,4.927H36.188V68.353a2.972,2.972,0,0,1-2.972,2.972H21.329a2.972,2.972,0,0,1-2.972-2.972V29.719H6.469a2.972,2.972,0,0,1-2.235-4.927Z"
                      transform="translate(17.306 0)"
                      fill="#fefefe"
                    ></path>
                    <path
                      data-v-de2bfc3e=""
                      id="Path_34911"
                      data-name="Path 34911"
                      d="M77.269,11V28.831H11.888V11H0V34.775a5.944,5.944,0,0,0,5.944,5.944H83.213a5.944,5.944,0,0,0,5.944-5.944V11Z"
                      transform="translate(0 54.381)"
                      fill="#fefefe"
                    ></path>
                  </g>
                </svg>
                <p>Drag and drop a file here or click</p>
              </div>
              <div v-else class="avatarEmpty show-img">
                <img
                  v-lazy="UserKYC.status !== -1 ? UserKYC.passport_image : passport_image"
                  width="225"
                />
                <button
                  v-if="UserKYC.status == -1"
                  class="button removi-img block bg-theme-1 text-white"
                  @click="removeImage(0)"
                >
                  Remove
                </button>
              </div>
            </div>

            <div class="form-group">
              <div class="imgInfo">
                <p class="text-left">
                  <span class="fileSize">Backside Of ID </span>
                  <br />
                  <span class="fileSize text-success">
                    * Make sure the image is full and clear and the format is jpg, jpeg.
                  </span>
                  <br />
                  <span class="fileSize text-success">
                    * Please use image up to maximum 10MB size
                  </span>
                </p>
              </div>
              <div
                class="avatarEmpty text-center"
                v-if="!passport_image_selfie && UserKYC.status == -1"
              >
                <input
                  @change="onFileChange($event, 1)"
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  class="fileUpload"
                />
                <svg
                  data-v-de2bfc3e=""
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 89.156 95.1"
                >
                  <g
                    data-v-de2bfc3e=""
                    id="upload-and-send"
                    transform="translate(0 0)"
                    opacity="0.3"
                  >
                    <path
                      data-v-de2bfc3e=""
                      id="Path_34910"
                      data-name="Path 34910"
                      d="M25.038,1.016a2.972,2.972,0,0,1,4.476,0l20.8,23.775a2.972,2.972,0,0,1-2.241,4.927H36.188V68.353a2.972,2.972,0,0,1-2.972,2.972H21.329a2.972,2.972,0,0,1-2.972-2.972V29.719H6.469a2.972,2.972,0,0,1-2.235-4.927Z"
                      transform="translate(17.306 0)"
                      fill="#fefefe"
                    ></path>
                    <path
                      data-v-de2bfc3e=""
                      id="Path_34911"
                      data-name="Path 34911"
                      d="M77.269,11V28.831H11.888V11H0V34.775a5.944,5.944,0,0,0,5.944,5.944H83.213a5.944,5.944,0,0,0,5.944-5.944V11Z"
                      transform="translate(0 54.381)"
                      fill="#fefefe"
                    ></path>
                  </g>
                </svg>
                <p data-v-de2bfc3e="">Drag and drop a file here or click</p>
              </div>
              <div v-else class="avatarEmpty show-img">
                <img
                  v-lazy="
                    UserKYC.status !== -1 ? UserKYC.passport_image_selfie : passport_image_selfie
                  "
                  width="225"
                />
                <button
                  v-if="UserKYC.status == -1"
                  class="button removi-img block bg-theme-1 text-white"
                  @click="removeImage(1)"
                >
                  Remove
                </button>
              </div>
            </div>
          </template>
          <div
            class="d-flex justify-content-center align-items-center submit flex-column mt-5"
            v-if="UserKYC.status == -1 && type != 0"
          >
            <b-button
              variant="success"
              :disabled="!passport_image || (type == 1 && !passport_image_selfie) || !passport"
              class="buttonSubmit btn-disable btn-egg"
              @click.prevent="uploadImage()"
            >
              Confirm
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import { geocode, filterComponents } from '@/plugins/Zipcode';

const lookup = require('country-code-lookup');

export default {
  components: {
    VuePhoneNumberInput,
  },
  data() {
    return {
      infoshow: true,
      kycshow: true,
      birthday: {
        date: '',
        month: '',
        year: '',
      },
      userinfo: {
        surname: '',
        name: '',
        birthday: '',
        address: '',
        phone: '',
        zipCode: '',
        placeBirthday: '',
        country: '',
      },
      reader: new FileReader(),
      passport_image: '',
      passport_image_selfie: '',
      passport: '',
      kyc: {
        passport_image: '',
        passport_image_selfie: '',
        passport: '',
        type: 1,
      },
      phoneshow: '',
      countryPhone: '',
      zipCode: {
        zipcode: null,
        city: null,
        country: null,
      },
      type: 0,
    };
  },
  computed: {
    ...mapGetters({
      UserKYC: 'user/UserKYC',
      UserInfo: 'user/UserInfo',
    }),
    kycCheck() {
      if (this.info) {
        return true;
      }
      return false;
    },
    isValidZipCode() {
      return false;
      // if (this.UserInfo.zipCode) {
      //   if (
      //     this.UserInfo.zipCode !== this.userinfo.zipCode
      //     && this.userinfo.zipCode !== this.zipCode.zipcode
      //   ) {
      //     return true;
      //   }
      //   return false;
      // }
      // if (this.userinfo.zipCode !== this.zipCode.zipcode) {
      //   return true;
      // }
      // return false;
    },
  },
  watch: {
    UserInfo: {
      handler() {
        this.userinfo = { ...this.UserInfo };
        if (this.userinfo.birthday && this.userinfo.birthday.length > 0) {
          const arrayDate = this.userinfo.birthday.split('-');
          this.birthday = {
            year: arrayDate[0],
            month: arrayDate[1],
            date: arrayDate[2],
          };
        }
        if (this.UserInfo.phone) {
          this.getCountryCodes(this.UserInfo.phone);
        }
      },
    },
    UserKYC: {
      handler() {
        this.ShowOption(this.UserInfo);
      },
    },
  },
  mounted() {
    if (this.UserInfo) {
      this.userinfo = { ...this.UserInfo };
      if (this.userinfo.birthday && this.userinfo.birthday.length > 0) {
        const arrayDate = this.userinfo.birthday.split('-');
        this.birthday = {
          year: arrayDate[0],
          month: arrayDate[1],
          date: arrayDate[2],
        };
      }
      if (this.UserInfo.phone) {
        this.getCountryCodes(this.UserInfo.phone);
      }
    }
  },
  created() {},
  methods: {
    async onUpdataInfo() {
      const { country, nationalNumber } = parsePhoneNumber(this.userinfo.phone);
      if (!country || !nationalNumber || !isValidPhoneNumber(this.userinfo.phone, country)) {
        this.$toastr.e('Incorrect telephone number');

        return;
      }
      if (!this.CheckCode) {
        this.$toastr.e(`Zip code does not exist in ${country}`);

        return;
      }
      const token = await this.genarateCaptChaV3('profile');
      if (!token) {
        this.$toastr.e('Too Fast! Please Wating Recaptcha.', 'Withdraw Failed');
      }
      const { date, month, year } = this.birthday;
      this.userinfo.birthday = `${year}-${month}-${date}`;

      this.userinfo.token = token;
      this.$store.dispatch('user/req_postUpdateInfo', this.userinfo);
    },
    OnValidationPhone(payload) {
      this.userinfo.phone = payload.formattedNumber;
    },
    onResetForm(type) {
      if (type === 'info') {
        this.userinfo = {
          surname: '',
          birthday: '',
          address: '',
          phone: '',
          zipCode: '',
          placeBirthday: '',
          country: this.UserInfo.country,
        };
        this.birthday = {
          date: '',
          year: '',
          month: '',
        };
      }
    },
    onFileChange(e, type) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      this.reader.onload = (ev) => {
        if (type === 0) {
          this.passport_image = ev.target.result;
          const [image] = files;
          this.kyc.passport_image = image;
        } else {
          this.passport_image_selfie = ev.target.result;
          const [Selfie] = files;
          this.kyc.passport_image_selfie = Selfie;
        }
      };
      this.reader.readAsDataURL(files[0]);
    },
    uploadImage() {
      this.kyc.passport = this.passport;
      this.kyc.type = this.type;
      this.$store.dispatch('user/req_postKYC', this.kyc);
    },
    removeImage(type) {
      if (type === 0) {
        this.passport_image = '';
      } else {
        this.passport_image_selfie = '';
      }
    },
    ShowOption(user) {
      if (this.UserKYC.status !== -1) {
        if (this.UserKYC.passport_image_selfie.length > 0) {
          this.type = 1;
        } else {
          this.type = 2;
        }
      }
      if (this.UserKYC.status === 1) {
        this.infoshow = true;
        this.kycshow = false;
      } else if (user && user.name) {
        this.infoshow = false;
        this.kycshow = true;
      } else {
        this.infoshow = true;
        this.kycshow = false;
      }
    },
    getCountryCodes(phone) {
      if (!phone) return;
      const { country, nationalNumber } = parsePhoneNumber(this.userinfo.phone);
      if (country && nationalNumber) {
        this.phoneshow = nationalNumber;
        this.countryPhone = country;
      }
    },
    CheckCode() {
      const latitude = '';
      const longitude = '';

      const geocoder = new window.google.maps.Geocoder();
      if (!this.userinfo.zipCode) return;

      geocoder.geocode(
        {
          region: lookup.byCountry(this.userinfo.country).iso2,
          address: this.userinfo.zipCode,
        },
        (results, status) => {
          if (status === 'OK') {
            if (
              lookup.byCountry(this.userinfo.country).iso2 === filterComponents(results, 'country')
            ) {
              this.zipCode.zipcode = this.userinfo.zipCode;
              return true;
            }
            this.zipCode = {
              zipcode: null,
              city: null,
              country: null,
            };
            return false;
          }
        },
      );
    },
  },
};
</script>

<style lang="scss">
.country-selector {
  padding: 0;
  .country-selector__label {
    display: none;
  }
  input {
    border: 0;
    border-bottom: 2px solid #e9ecef;
    border-radius: 0;
    height: 40px;
    font-size: clamp(15px, 1vw, 16.2px);
    font-weight: 600;
    font-size: 18px;
    color: #23222f;
    &:focus {
      box-shadow: unset;
      border-bottom: 2px solid #FF5C00;
    }
    &::placeholder {
      font-weight: 500;
      font-size: clamp(13px, 1vw, 15px);
    }
  }
}
.input-phone-number {
  padding-top: 0px;
  .input-tel__label {
    margin-bottom: auto;
    color: #98a6ad !important;
    font-size: 11px !important;
  }
  input.input-tel__input {
    font-size: 16px !important;
    color: #23222f;
    caret-color: #23222f !important;
    &:focus {
      box-shadow: unset !important;
      border-bottom: 2px solid #FF5C00 !important;
    }
    &::placeholder {
      font-weight: 500;
      font-size: clamp(13px, 1vw, 15px);
    }
  }
}
.birthday-input {
  display: flex;
  justify-content: center;
  align-items: center;
  .day,
  .month,
  .year {
    flex: 1;
    text-align: center;
    label {
      font-size: 12px !important;
      font-weight: 600;
      margin-bottom: 0px !important;
    }
  }
  input {
    border-right: 2px solid #00000038 !important;
    text-align: center;
    &::placeholder {
      font-weight: 600;
    }
    appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
.box-kyc {
  width: 100%;
  min-height: 40px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 7px 10px 2px #00000038;
  position: relative;
  margin-bottom: 1.25rem;
  .box-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
    padding: 0 1.25rem;
    background-color: #FF5C00;
    border-radius: 10px 10px 10px 10px;
    .text {
      color: #ffff;
      font-weight: 600;
      font-size: 20px;
      text-transform: capitalize;
      .text-warning {
        font-size: clamp(15px, 2vw, 16px);
      }
    }
    .icon {
      cursor: pointer;
      img {
        height: 20px;
        width: auto;
      }
      &.active {
        transform: rotate(180deg);
      }
    }
  }
  .content-box {
    padding: 1.25rem;
    .text-error {
      color: red;
      font-size: 13px;
      font-weight: 700;
      text-transform: capitalize;
    }
    .form-group {
      label {
        font-weight: 600;
        color: #6c757d;
        font-size: 16px;
        margin-bottom: 5px;
      }
      select,
      input {
        width: 100%;
        border: 0;
        border-bottom: 2px solid #e9ecef;
        border-radius: 0;
        height: 40px;
        font-size: clamp(15px, 1vw, 16.2px);
        font-weight: 600;
        font-size: 18px;
        color: #23222f;
        &:focus {
          box-shadow: unset;
          border-bottom: 2px solid #FF5C00;
        }
        &::placeholder {
          font-weight: 500;
          font-size: clamp(13px, 1vw, 15px);
        }
      }
      .form-control {
        border: 0;
        border-bottom: 2px solid #e9ecef;
        border-radius: 0;
        height: 40px;
        font-size: clamp(15px, 1vw, 16.2px);
        font-weight: 600;
        font-size: 18px;
        color: #23222f;
        &:focus {
          box-shadow: unset;
          border-bottom: 2px solid #FF5C00;
        }
        &::placeholder {
          font-weight: 500;
          font-size: clamp(13px, 1vw, 15px);
        }
      }
    }
    .close {
      color: #fff;
    }
    .removi-img {
      position: absolute;
      top: calc(50% - 15px);
      left: 0;
      right: 0;
      margin: auto;
      background: #f3d91f;
      border: 1px #f3d91f solid;
      border-radius: 5px;
      padding: 7px 20px;
      opacity: 0;
      z-index: 20;
      width: 200px;
      transition: 0.3s;
      transform: translateY(100%);
      color: #000 !important;
    }
    .show-img:hover .removi-img {
      transform: translateY(0);
      opacity: 1;
    }
    .show-img {
      padding: 10px !important;
      height: auto !important;
      position: relative !important;
    }
    .show-img img {
      position: relative;
      z-index: 10;
      max-width: 225px;
    }
    .show-img {
      &:hover {
        &::after {
          background: rgba(0, 0, 0, 0.3);
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          border-radius: 10px;
          height: 100%;
          z-index: 11;
          content: '';
        }
      }
    }
    svg {
      font-size: 30px;
    }
    #uploadImage {
      .avatarEmpty {
        background: #1111111f;
        padding: 45px 0;
        color: #424242;
        border-radius: 10px;
        height: 180px;
        margin-bottom: 23px;
        width: 100%;
        position: relative;
        cursor: pointer;
        text-align: center;

        .fileUpload {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 22;
          outline: none;
          opacity: 0;
        }

        p {
          font-size: 20px;
          font-weight: bold;
          margin-top: 20px;
          margin-bottom: 0;
        }
      }

      .imgInfo {
        text-align: center;
        margin-bottom: 20px;

        .colorGreen {
          color: #25e69c;
          margin: 0 10px;
        }
      }
    }
  }
}
.box-change-img {
  .title-section {
    border-bottom: 2px solid #FF5C00;
    border-top: 2px solid #FF5C00;
    text-align: center;
    font-weight: 600;
  }
  .nav-kyc {
    display: flex;
    justify-content: center;
    margin: 15px auto;
    .item {
      border: 1px solid #FF5C00;
      box-shadow: 0 2px 6px 0 #ccc;
      margin: 5px;
      padding: 5px 15px;
      display: block;
      width: 145px;
      text-align: center;
      border-radius: 15px;
      color: #23222f;
      font-weight: 600;
      cursor: pointer;
      &.active {
        background: #FF5C00;
        color: #fff;
      }
    }
  }
}
</style>
